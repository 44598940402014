import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { checkUser, removeUserPro } from '../services/api';
import { AppStateContext } from '../contexts/appContext';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, Grid } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://codingx.app/">
                CodingX
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export function RemoveUser() {
    const [, dispatch] = React.useContext(AppStateContext);
    const [email, setEmail] = React.useState('');
    const [app, setApp] = React.useState('CodingX');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleAppChange = (event) => {
        setApp(event.target.value);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const userStatus = await checkUser(app, email);
        if (userStatus === 0) {
            toast(`${email} already does not have pro access to ${app}.`);
        }
        if (userStatus === 2) {
            toast(`User ${email} not found in ${app}. Install ${app} and signup before trying again.`);
        }
        if (userStatus === 1) {
            await removeUserPro(email, app);
            toast(`Revoked ${email}'s pro access to ${app}.`);
        }
    };
    const handleBack = async () => {
        dispatch({ mode: '' });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                sx={{ mr: 3 }}
                                onClick={handleBack}
                            >
                                ←
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography component="h1" variant="h5">
                                Remove Pro User
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            onChange={handleEmailChange}
                            autoFocus
                        />
                        <FormControl
                            margin="normal"
                            required
                            fullWidth>
                            <InputLabel id="select-label">App</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                value={app}
                                label="App"
                                onChange={handleAppChange}
                            >
                                <MenuItem value={"CodingX"}>CodingX</MenuItem>
                                <MenuItem value={"CX"}>CX</MenuItem>
                                <MenuItem value={"CppX"}>CppX</MenuItem>
                                <MenuItem value={"JavaX"}>JavaX</MenuItem>
                                <MenuItem value={"PythonX"}>PythonX</MenuItem>
                                <MenuItem value={"HackerX"}>HackerX</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Remove Pro User
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
            <ToastContainer />
        </ThemeProvider>
    );
}