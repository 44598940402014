import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { checkUser, makeUserPro } from '../services/api';
import { AppStateContext } from '../contexts/appContext';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, Grid } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateTime } from "luxon";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://codingx.app/">
                CodingX
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export function AddUser() {
    const [, dispatch] = React.useContext(AppStateContext);
    const [email, setEmail] = React.useState('');
    const [app, setApp] = React.useState('CodingX');
    const [period, setPeriod] = React.useState('Month(s)');
    const [duration, setDuration] = React.useState(0);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleAppChange = (event) => {
        setApp(event.target.value);
    };
    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
    };
    const handleDurationChange = (event) => {
        const regex = period === "Month(s)" ? /\b(0?[1-9]|[1][0-2])\b/ : /\b(0?[1-9]|[1-3][0-9])\b/;
        if (event.target.value === '' || regex.test(event.target.value)) {
            setDuration(event.target.value);
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const userStatus = await checkUser(app, email);
        if (userStatus === 1) {
            toast(`${email} already has pro access to ${app}.`);
        }
        if (userStatus === 2) {
            toast(`User ${email} not found in ${app}. Install ${app} and signup before trying again.`);
        }
        if (userStatus === 0) {
            const promoDetails = {
                duration: duration === 0 ? "Lifetime" : `${duration} ${period}`,
                expiry: duration === 0 ? "Never" : period === "Month(s)" ? DateTime.now().plus({ months: duration }).ts : DateTime.now().plus({ days: duration }).ts
            };
            await makeUserPro(email, app, promoDetails);
            toast(`${email} now has pro access to ${app}.`);
        }
    };
    const handleBack = async () => {
        dispatch({ mode: '' });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                sx={{ mr: 3 }}
                                onClick={handleBack}
                            >
                                ←
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography component="h1" variant="h5">
                                Add Pro User
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            onChange={handleEmailChange}
                            autoFocus
                        />
                        <FormControl
                            margin="normal"
                            required
                            fullWidth>
                            <InputLabel id="select-label">App</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                value={app}
                                label="App"
                                onChange={handleAppChange}
                            >
                                <MenuItem value={"CodingX"}>CodingX</MenuItem>
                                <MenuItem value={"CX"}>CX</MenuItem>
                                <MenuItem value={"CppX"}>CppX</MenuItem>
                                <MenuItem value={"JavaX"}>JavaX</MenuItem>
                                <MenuItem value={"PythonX"}>PythonX</MenuItem>
                                <MenuItem value={"HackerX"}>HackerX</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            margin="normal"
                            id="duration"
                            label="Duration"
                            name="duration"
                            type="number"
                            autoFocus
                            value={duration}
                            onChange={handleDurationChange}
                            sx={{ mr: 2 }}
                        />
                        <FormControl
                            margin="normal"
                        >
                            <InputLabel id="select-label">Period</InputLabel>
                            <Select
                                labelId="select-label"
                                id="select"
                                value={period}
                                label="Period"
                                onChange={handlePeriodChange}
                            >
                                <MenuItem value={"Month(s)"}>Month(s)</MenuItem>
                                <MenuItem value={"Day(s)"}>Day(s)</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Add Pro User
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
            <ToastContainer />
        </ThemeProvider>
    );
}